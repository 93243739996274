<template>
  <span
    v-b-tooltip.topleft.v-secondary.noninteractive="{
      title: `${tooltip_text ? tooltip_text : ''}`,
      boundary: 'viewport',
    }"
  >
    <b-button
      size="sm"
      :variant="variant"
      :title="text_button"
      @click="handleClick"
      class="noprint"
      :class="custom_class"
      :disabled="disabled_button"
    >
      <b-icon-plus v-if="icon == 'plus'" scale="1.5"></b-icon-plus>
      <b-icon-pencil-square v-else-if="icon == 'square'"></b-icon-pencil-square>
      <b-icon-trash v-else-if="icon == 'trash'"></b-icon-trash>
      <slot name="icon"></slot>
      <span v-if="text_button" class="">
        {{ text_button }}
      </span>
    </b-button>
  </span>
</template>

<script>
export default {
  name: "ButtonMedium",
  props: {
    text_button: {
      type: String,
      required: false,
    },
    tooltip_text: {
      type: String,
      required: true,
    },
    click_button: {
      type: Function,
      required: false,
    },
    size: {
      type: String,
      default: "sm",
    },
    variant: {
      type: String,
      default: "none",
    },
    icon: {
      type: String,
      default: "",
      validator: (value) => ["plus", "square", "trash", ""].includes(value),
    },
    custom_class: {
      type: String,
      default: "",
    },
    disabled_button: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    handleClick() {
      // Si se proporciona una función de click, se ejecuta
      if (!this.disabled_button) {
        if (this.click_button) {
          this.click_button();
        } else {
          this.$emit("click");
        }
      }
    },
  },
};
</script>

<style scoped>
</style>